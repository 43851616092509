<template>
    <span
      :class="{active: isActiveHour, block: isBlockHour}"
      @click="$emit('setHour', numberHour - 1)"
    >
      {{ numberHour - 1 }}
    </span>
</template>

<script>
export default {
  name: 'hour-element',
  inject: ['$value', '$startDate'],
  props: {
    numberHour: {
      type: Number
    }
  },
  computed: {
    isActiveHour () {
      return this.numberHour - 1 === new Date(+this.$value()).getHours()
    },
    isBlockHour () {
      if (this.datesAreOnSameDay) {
        const dateStart = new Date(this.$startDate())
        return this.numberHour - 1 < dateStart.getHours() ||
            (this.numberHour - 1 === dateStart.getHours() && dateStart.getMinutes() === 59)
      }
      return false
    },
    datesAreOnSameDay () {
      const dateCheck = new Date(+this.$value())
      const dateStart = new Date(this.$startDate())
      return dateCheck.getFullYear() === dateStart.getFullYear() &&
          dateCheck.getMonth() === dateStart.getMonth() &&
          dateCheck.getDate() === dateStart.getDate();
    }
  }
}
</script>

<style scoped>

</style>
