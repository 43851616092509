<template>
    <span
      :class="{active: isActiveMinutes, block: isBlockMinutes}"
      @click="$emit('setMinute', numberMinutes - 1)"
    >
      {{ numberMinutes - 1 }}
    </span>
</template>

<script>
export default {
  name: 'minutes-element',
  inject: ['$value', '$startDate'],
  props: {
    numberMinutes: {
      type: Number
    }
  },
  computed: {
    isActiveMinutes () {
      return this.numberMinutes - 1 === new Date(+this.$value()).getMinutes()
    },
    isBlockMinutes () {
      if (this.datesAreOnSameDay) {
        const dateStart = new Date(this.$startDate())
        const dateCheck = new Date(+this.$value())
        return dateCheck.getHours() === dateStart.getHours() && this.numberMinutes - 1 < dateStart.getMinutes()
      }
      return false
    },
    datesAreOnSameDay () {
      const dateCheck = new Date(+this.$value())
      const dateStart = new Date(this.$startDate())
      return dateCheck.getFullYear() === dateStart.getFullYear() &&
          dateCheck.getMonth() === dateStart.getMonth() &&
          dateCheck.getDate() === dateStart.getDate();
    }
  }
}
</script>

<style scoped>

</style>
