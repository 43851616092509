var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"rir-input",class:{
    readonly: _vm.readonly
  },style:({
    maxWidth: _vm.maxWidth
  }),on:{"click":function($event){$event.stopPropagation();return _vm.focusInput($event)}}},[_c('div',{staticClass:"rir-input__body",class:{
      'not-label': _vm.isLabel
    }},[_c('div',{staticClass:"rir-input__input"},[((_vm.type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"disabled":_vm.readonly,"placeholder":_vm.placeholder,"min":_vm.minNumber,"max":_vm.maxNumber,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"blur":function($event){return _vm.$emit('blur', $event.target.value)},"keyup":function($event){return _vm.$emit('keyup', $event.target.value)},"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}},'input',_vm.paramsInput,false)):((_vm.type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"disabled":_vm.readonly,"placeholder":_vm.placeholder,"min":_vm.minNumber,"max":_vm.maxNumber,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"blur":function($event){return _vm.$emit('blur', $event.target.value)},"keyup":function($event){return _vm.$emit('keyup', $event.target.value)},"change":function($event){_vm.model=null}}},'input',_vm.paramsInput,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"disabled":_vm.readonly,"placeholder":_vm.placeholder,"min":_vm.minNumber,"max":_vm.maxNumber,"type":_vm.type},domProps:{"value":(_vm.model)},on:{"blur":function($event){return _vm.$emit('blur', $event.target.value)},"keyup":function($event){return _vm.$emit('keyup', $event.target.value)},"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}},'input',_vm.paramsInput,false)),(_vm.isLabel)?_c('label',{staticClass:"rir-input__label",class:{
          active: _vm.isModel
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()])]),(_vm.$slots.before)?_c('div',{staticClass:"rir-input__before"},[_vm._t("before")],2):_vm._e(),(_vm.$slots.after)?_c('div',{staticClass:"rir-input__after"},[_vm._t("after")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }