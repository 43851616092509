var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"rir-date-picker__column",class:{
    active: _vm.isActive.isActive,
    start: _vm.isActive.isStart,
    finish: _vm.isActive.isFinish,
    period: _vm.$period(),
    block: _vm.isBlock,
    nowDate: _vm.nowDate,
    otherMonth: _vm.item.month !== _vm.month
  },on:{"click":function($event){return _vm.$emit('selectDate', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.day)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }